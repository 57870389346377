import {
    assetClassContainUnits,
    investmentFallbackStats,
    isUnitsBased,
    opportunityClosedStates,
} from '@helpers/constants';
import {
    getEitherOrDefaultValueOf,
    isValidResponseEntity,
} from '@helpers/utils';
import { getFestiveSeasonConfiguration, getOpenConfigurationByEntityType } from '@services/configuration.service';
import { hasWindow } from '@services/http.service';
import { removeAuth, getAuth } from '@services/identity.service';
import { getPaymentById } from '@services/payment.service';
import { getKycNudgeDetailsByUserId } from '@services/users.service';
import * as OpportunityHelper from "@ui/helpers/opportunities/helper";
import { floor, sumBy } from 'lodash';
import Moment from 'moment';
import { theme } from 'tailwind.config';
import { brandNames } from './enums';
import { OpportunityTypes } from '@ui/helpers/enums/opportunity-types';
import { gaEventNameEnum } from './enums/gaEventNameEnum';
import { toast } from 'react-toastify';
import { paymentGatewayEnum } from './enums/paymentGatewayEnum';

export function debounce(func, wait, immediate) {
    var timeout;
    return function executedFunction() {
        var context = this;
        var args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export const parseData = (data) => {
    return JSON.parse(data);
};

export const mailToGmail = (value) =>
    `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${value}`;

export const callToNumber = (value) => `tel:${value}`;

export const getNumberWithComma = (number) => {
    return number && number.toLocaleString('en-IN');
};

export const getNumberString = (number) => {
    return number ? number.toLocaleString('en-IN') : number;
};

export const getNumberAsCurrency = (
    number,
    locale = 'en-IN',
    currency = 'INR'
) => {
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 0, // Set minimumFractionDigits to 0
        maximumFractionDigits: number % 1 > 0 ? 2 : 0, // Set maximumFractionDigits based on decimal presence
    };

    return number?.toLocaleString(locale, options);
};

export const scrollToTargetAdjusted = (reference, headerOffset = 64) => {
    let elementPosition = reference.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getInitials = (fullName) => {
    let names = fullName.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
};

export const getNumberInLakhAndCrore = (value) => {
    if (value >= 10000000000) {
        const startingValue = String(value).slice(0, 6);
        const valueToShow =
            startingValue[4] === '0'
                ? startingValue.slice(0, 4)
                : startingValue.slice(0, 4) + '.' + startingValue.slice(4, 6);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000000 && value >= 1000000000) {
        const startingValue = String(value).slice(0, 5);
        const valueToShow =
            startingValue[3] === '0'
                ? startingValue.slice(0, 3)
                : startingValue.slice(0, 3) + '.' + startingValue.slice(3, 5);
        return `${valueToShow} Cr`;
    }
    const updatedValue = String(value).slice(0, 4);
    if (value < 1000000000 && value >= 100000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Cr`;
    }
    if (value < 100000000 && value >= 10000000) {
        const valueToShow =
            updatedValue[1] === '0' && updatedValue[2] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Cr`;
    }
    if (value < 10000000 && value >= 1000000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} Lac(s)`;
    }
    if (value < 1000000 && value >= 100000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} Lac(s)`;
    }
    if (value < 100000 && value >= 10000) {
        const valueToShow =
            updatedValue[2] === '0'
                ? updatedValue.slice(0, 2)
                : updatedValue.slice(0, 2) + '.' + updatedValue.slice(2, 4);
        return `${valueToShow} K`;
    }
    if (value < 10000 && value >= 1000) {
        const valueToShow =
            updatedValue[1] === '0'
                ? updatedValue.slice(0, 1)
                : updatedValue.slice(0, 1) + '.' + updatedValue.slice(1, 3);
        return `${valueToShow} K`;
    }
    return getNumberWithComma(value);
};

export const removeTrailingZeros = (value) => {
    const valueToShow = (value).toFixed(2);
    /* Remove trailing zeros
        // 1.00 -> 1
        // 1.10 -> 1.1
        // 1.55 -> 1.55
        // 2.05 -> 2.05
    */
    return valueToShow.replace(/(\.0+|0+)$/, '');
};

export const formatAmountInMoney = (amount, width) => {
    if (amount >= 10000000) {
        const valueToShow = removeTrailingZeros(amount / 10000000);
        return `${valueToShow} Cr`;
    } else if (amount >= 100000) {
        const valueToShow = removeTrailingZeros(amount / 100000);
        return `${valueToShow} ${(width && width < 768) ? 'L' : 'Lac(s)'}`;
    } else if (amount >= 1000) {
        const valueToShow = removeTrailingZeros(amount / 1000);
        return `${valueToShow} K`;
    }
    return getNumberWithComma(amount);
}

export const getMinInvestmentForOpportunity = ({
    opportunity,
    minInvestmentAmount,
    isFTI,
}) => {
    switch (opportunity.type) {
        case OpportunityTypes.CORPORATE_DEBT:
        case OpportunityTypes.VENTURE_DEBT:
        case OpportunityTypes.TREASURY_BILL:
            return getNumberWithComma(
                Math.round(
                    opportunityClosedStates.includes(opportunity.state)
                        ? opportunity.initialFaceValue *
                        opportunity.minInvestmentUnit
                        : opportunity.dailyDebenturePrice *
                        opportunity.minInvestmentUnit
                )
            );
        case OpportunityTypes.ASSET_BACKED_LEASING:
        case OpportunityTypes.INVOICE_DISCOUNTING:
            return getNumberWithComma(
                getEitherOrDefaultValueOf(
                    minInvestmentAmount,
                    isFTI
                        ? getEitherOrDefaultValueOf(
                            opportunity?.minInvestmentAmountForFTI,
                            opportunity?.minInvestmentAmount
                        )
                        : opportunity?.minInvestmentAmount
                )
            );
    }
};

export const getMaxInvestmentForOpportunity = (
    opportunityType,
    maxInvestmentAmount,
    showMaxInvestmentAmount
) => {
    if (showMaxInvestmentAmount && OpportunityHelper.isInvoiceDiscountingOpportunity(opportunityType)) {
        return `₹ ${getNumberString(maxInvestmentAmount)}`;
    }
    return null;
};

export const getStatusColor = (status) => {
    switch (status) {
        case 'Received':
            return 'text-semantic-success-base';
        case 'Delayed':
            return 'text-semantic-error-base';
        case 'In Process':
            return 'text-semantic-warning-base';
        default:
            return 'text-gray-500';
    }
};

export const getStatusColorImage = (status) => {
    switch (status) {
        case 'Received':
            return '/images/posEllipse.svg';
        case 'Delayed':
            return '/images/negEllipse.svg';
        case 'In Process':
            return '/images/UpcomingEllipse.svg';
        default:
            return '/images/grayEllipse.svg';
    }
};

// TODO: use single method for all assets
export const getStatusBadgeStyles = (status) => {
    switch (status) {
        case 'Received':
            return 'text-semantic-success-base bg-semantic-success-light';
        case 'Delayed':
            return 'text-semantic-error-base bg-semantic-error-light';
        case 'In Process':
            return 'text-semantic-warning-base bg-semantic-warning-light whitespace-nowrap';
        default:
            return 'text-semantic-info-base bg-semantic-info-light';
    }
};

// TODO: use single method for all assets
export const getStatusBadgeStylesForAssetBackedLeasingAndInvoiceDiscounting = (
    status
) => {
    switch (status) {
        case 'Repaid':
            return 'text-semantic-success-base bg-semantic-success-light';
        case 'Delayed':
            return 'text-semantic-error-base bg-semantic-error-light';
        case 'Repayment in progress':
            return 'text-semantic-info-base bg-semantic-info-light';
        case 'In Process':
            return 'text-semantic-warning-base bg-semantic-warning-light whitespace-nowrap';
        default:
            return 'text-semantic-info-base bg-semantic-info-light';
    }
};

export const amountToBeMultipleOf = (amount, investmentMultiple) =>
    amount % investmentMultiple === 0;
// DEPRICATED!
export const isAssetBackedLeasingOpportunity = (opportunityType) => OpportunityHelper.isAssetBackedLeasingOpportunity(opportunityType);
// DEPRICATED!
export const isCorporateDebtOpportunity = (opportunityType) => OpportunityHelper.isCorporateDebtOpportunity(opportunityType);
// DEPRICATED!
export const isVentureDebtOpportunity = (opportunityType) => OpportunityHelper.isVentureDebtOpportunity(opportunityType);
// DEPRICATED!
export const isTreasuryBillOpportunity = (opportunityType) => OpportunityHelper.isTreasuryBillOpportunity(opportunityType);
// DEPRICATED!
export const isInvoiceDiscountingOpportunity = (opportunityType) => OpportunityHelper.isInvoiceDiscountingOpportunity(opportunityType);
// DEPRICATED!
export const isNCDOpportunity = (opportunityType) => {
    return assetClassContainUnits.includes(opportunityType);
};
export const getDecimalNumberWithComma = (number, decimalPlace = 4) => {
    number = parseFloat(number).toFixed(decimalPlace);
    return number.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
};

export const checkInProcessStatus = (repaymentDate, gracePeriod = 0) => {
    let today = Moment(Moment().utcOffset(330).format('YYYY-MM-DD'));
    let repaymentDateInFormat;

    const dateFormats = [
        'YYYY-MM-DDTHH:mm:ss.SSSZ',
        'DD-MMM-YYYY',
        'MM-DD-YYYY',
        'DD MMM YYYY',
        'DD MMMM YYYY',
        'MMMM DD, YYYY',
        'YYYY/MM/DD',
        'YYYY-MM-DD',
        'DD/MM/YYYY',
        'MM/DD/YYYY',
        'MM/DD/YY',
        'DD/MM/YY',
        'DD-MM-YYYY',
        'MM-DD-YY',
        'DD-MM-YYYY HH:mm:ss',
        // Add more formats as needed
    ];


    for (const format of dateFormats) {
        const parsedDate = Moment(repaymentDate, format, true);
        if (parsedDate.isValid()) {
            repaymentDateInFormat = parsedDate.format('YYYY-MM-DD');
            break;
        }
    }

    if (!repaymentDateInFormat) {
        repaymentDateInFormat = Moment(repaymentDate).format('YYYY-MM-DD');
    }

    const gracePeriodDate = Moment(repaymentDateInFormat)
        .add(gracePeriod, 'days')
        .format('YYYY-MM-DD');

    if (
        today.isSameOrBefore(gracePeriodDate) &&
        today.isAfter(repaymentDateInFormat)
    ) {
        return 'In Process';
    }
    return 'Delayed';
};

export const getPaymentStatus = (payment, gracePeriod = 0) => {
    if (payment.status === 'Delayed') {
        return checkInProcessStatus(payment.date, gracePeriod);
    }
    return (payment?.status === 'UpComing' || !payment?.status) ? 'Upcoming' : payment.status;
};

// TODO: use single method for all assets
export const getPaymentStatusForAssetBackedLeasingAndInvoiceDiscounting = (
    payment,
    gracePeriod = 0
) => {
    if (
        payment.status === 'Received' ||
        payment.repaymentTransferRecords?.length
    ) {
        if (
            isAllTransferSuccessful(payment.repaymentTransferRecords) ||
            payment.type === 'Refund'
        ) {
            return 'Repaid';
        } else {
            return 'Repayment in progress';
        }
    }
    if (payment.status === 'Delayed') {
        return checkInProcessStatus(payment.date, gracePeriod);
    }
    return (payment?.status === 'UpComing' || !payment?.status) ? 'Upcoming' : payment.status;
};

/**
 *  When we use  Moment for date format conversion,
 *  we need to mention the current format of the date and in which format we want to convert.
 *
 *  because on Chrome browser, date format conversion works fine without ,
 *  giving the current format of the date.
 *
 *  But for Firefox and other browsers,
 *  Moment will return an invalid date if the date is in string format.
 *
 *  We can avoid invalid date errors by mentioning the date's current format.
 *
 */
export const formatDate = (date, format = 'MMM D, YYYY', offset = '+05:30') => {
    if (date && Moment(date).isValid()) {
        return Moment.utc(date).utcOffset(offset).format(format);
    } else if (date && Moment(date, 'MM-DD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DD-YYYY').utc().utcOffset(offset).format(format);
    } else if (date && Moment(date, 'MM-DDD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DDD-YYYY').utc().utcOffset(offset).format(format);
    }
    return '';
}

export const checkIfUpdatingMaxInvestmentAmountPossible = (opportunity) => {
    return (
        ['InDraft', 'SubcriptionReady'].includes(opportunity.state) ||
        (opportunity.state === 'InvestmentReady' &&
            opportunity.accessCode ===
            process.env.NEXT_PUBLIC_ADMIN_ACCESS_CODE)
    );
};

export const downloadUrl = async (url) => {
    var link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const getNumberWithFourdecimal = (number) => {
    if (typeof number === 'string') {
        return Number(number).toFixed(4);
    }
    return number.toFixed(4);
};

export const stringifyData = (data) => {
    return JSON.stringify(data);
};

export const startTimer = (expiry, setMins, setSecs) => {
    let myInterval = setInterval(() => {
        let diff = Math.abs(expiry - new Date().getTime()) / 1000;
        setMins(String(Math.floor(diff / 60) % 60).padStart(2, '0'));
        setSecs(String(Math.floor(diff % 60)).padStart(2, '0'));
    }, 1000);
    return () => {
        clearInterval(myInterval);
    };
};

export const getMobileWithoutCountryCode = (mobile = '', countryCode = '') => {
    return mobile?.replace(countryCode?.substring(1), '');
};

export const getQueryParamsFromUrl = (search, query) => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(query);
};

export const isUndefined = (value) => {
    return value === undefined;
};

export const getMaskMobileNumber = (mobileNumber) => {
    return mobileNumber.replace(/^.{6}/g, '******');
};

export const getFormattedPhoneNumber = (number, countryCode) => {
    const countryCodeLength = countryCode.length;
    const phoneNumber = number.slice(countryCodeLength - 1);

    return `${countryCode} - ${phoneNumber}`;
};

/**
 *  When we use  Moment for date format conversion,
 *  we need to mention the current format of the date and in which format we want to convert.
 *
 *  because on Chrome browser, date format conversion works fine without ,
 *  giving the current format of the date.
 *
 *  But for Firefox and other browsers,
 *  Moment will return an invalid date if the date is in string format.
 *
 *  We can avoid invalid date errors by mentioning the date's current format.
 *
 */
export const getValidDate = (date, format = 'DD-MMM-YYYY') => {
    if (date && Moment(date).isValid()) {
        return Moment.utc(date).utcOffset('+05:30').format(format)
    } else if (date && Moment(date, 'MM-DD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DD-YYYY').utc().utcOffset('+05:30').format(format)
    } else if (date && Moment(date, 'MM-DDD-YYYY', true).isValid()) {
        return Moment(date, 'MM-DDD-YYYY').utc().utcOffset('+05:30').format(format)
    }
    return '';
}

export const getTenure = (startDate, endDate) => {
    if (!startDate)
        startDate = Moment.utc().utcOffset('+05:30').format('DD MMM YYYY');
    else
        startDate = getValidDate(startDate, 'DD MMM YYYY');

    endDate = getValidDate(endDate, 'DD MMM YYYY');

    const months = Math.round(
        Moment(endDate).diff(Moment(startDate), 'days') / 30
    );

    if (months < 6) {
        const days = Math.round(
            Moment(endDate).diff(Moment(startDate), 'days')
        );
        return days > 0 ? days + ' D' : 0 + ' D';
    }
    if (months > 24)
        return `${(months / 12) | 0} Y ${months % 12 === 0 ? '' : (months % 12) + ' M'
            }`;
    return months + ' M';
};

export const getSheetData = (data, header) => {
    var fields = Object.keys(data[0]);
    var sheetData = data.map((row) => {
        return fields.map((fieldName) => {
            return row[fieldName] || row[fieldName] === 0 ? row[fieldName] : '';
        });
    });
    sheetData.unshift(header);
    return sheetData;
};

export const getNumberWithTwoDecimal = (number) => {
    if (!number) return null;
    if (typeof number === 'string') {
        return Number(number).toFixed(2);
    }
    return number.toFixed(2);
};

export const getTaxableAmount = (amount, tax) => {
    if (amount > 0) {
        return amount * (tax / 100);
    }
    return amount;
};

export const isCounterPartyPerformanceExists = (counterPartyPerformance) => {
    return (
        counterPartyPerformance && counterPartyPerformance.totalMaturedDeals > 0
    );
};

export const isAllTransferSuccessful = (repaymentTransferRecords) => {
    return (
        repaymentTransferRecords &&
        repaymentTransferRecords.every(
            (repayment) => repayment.status === 'TransferSuccessful'
        )
    );
};

export const getPostTaxRepaymentAmount = (repaymentTransferRecords) => {
    return repaymentTransferRecords?.length
        ? sumBy(repaymentTransferRecords, 'principal') +
        sumBy(repaymentTransferRecords, 'postTaxInterest') +
        sumBy(repaymentTransferRecords, 'postTaxPenalCharges')
        : 0;
};

export const getPreTaxRepaymentAmount = (repaymentTransferRecords) => {
    return (
        sumBy(repaymentTransferRecords, 'principal') +
        sumBy(repaymentTransferRecords, 'interest')
    );
};

export const getFloorAmount = (amount) => floor(amount, 2);

export const getNestedFieldValue = (
    obj,
    fieldPath,
    defaultValue = 'N/A',
    typeOfObj = null
) => {
    const fields = fieldPath.split('.');
    let value = obj;
    for (const field of fields) {
        value = value?.[field];
        if (value === undefined || value === null) {
            return defaultValue;
        }
    }
    return typeof value === 'boolean' ? typeOfObj[value] : value;
};

export const calculatedSystemInterest = (
    discountRate,
    tenure,
    disbursedAmount
) => Number((discountRate / (365 * 100)) * tenure * disbursedAmount).toFixed(4);

export const getBorrowerPreferenceAlternativeName = (preferenceType) => {
    switch (preferenceType) {
        case 'FromDateOfFirstDisbursal':
            return 'A1';

        case 'FromDateOfLastDisbursal':
            return 'A2';

        case 'FromDateOfEachDisbursal':
            return 'A3';
        case 'SingleTranche':
            return 'B';
        default:
            return '';
    }
};

export const getBorrowerPreferenceDescription = (
    preferenceType,
    trancheTenure
) => {
    switch (preferenceType) {
        case 'FromDateOfFirstDisbursal':
            return `Borrower will repay post ${trancheTenure} days from the date of first disbursal.`;

        case 'FromDateOfLastDisbursal':
            return `Borrower will repay post ${trancheTenure} days from the date of last disbursal.`;

        case 'FromDateOfEachDisbursal':
            return `Borrower will repay post ${trancheTenure} days from the date of each disbursal.`;
        case 'SingleTranche':
            return `Borrower will receive total deal amount at once and will repay ${trancheTenure} days from the disbursal.`;
        default:
            return '';
    }
};

export const getToLocaleString = (value) => {
    if (value) return `₹ ${value.toLocaleString('en-IN')}`;
    return 0;
};

export const onAuthChange = (authToken) => {
    // clearing storage while logout or autoLogout
    if (!authToken && hasWindow()) {
        Object.keys(localStorage).forEach((key) => {
            if (
                key !== 'investmentStats' &&
                key !== 'festiveSeason' &&
                key !== 'festiveSeasonSignup'
            )
                localStorage.removeItem(key);
        });
        sessionStorage.clear();
    }
};

export const getHomePageStats = (data) => {
    if (data) {
        return [
            {
                suffix: 'rupeeSymbol',
                amount: data?.entity?.investedAmount
                    ? data?.entity?.investedAmount
                    : investmentFallbackStats.investedAmount,
                tagline: 'Crores',
                title: 'Total Investment',
                color: theme?.colors?.primary?.[500],
                category: '',
                duration: 1.3,
                countSuffix: '+'
            },
            {
                suffix: 'rupeeSymbol',
                amount: data?.entity?.repaidAmount
                    ? data?.entity?.repaidAmount
                    : investmentFallbackStats.repaidAmount,
                tagline: 'Crores',
                title: 'Repayments Made',
                color: theme?.colors?.primary?.[500],
                category: '',
                delay: 0.6,
                duration: 1.2,
                countSuffix: '+'
            },
            {
                suffix: 'percentageSymbol',
                amount: data?.entity?.averageReturns
                    ? data?.entity?.averageReturns
                    : investmentFallbackStats.averageReturns,
                tagline: '',
                title: 'Avg Returns Earned',
                color: theme?.colors?.primary?.[30],
                category: 'Per Annum',
                categoryShortName: 'p.a',
                decimalPlaces: 1,
                duration: 0.5,
                delay: 1.5,
                countSuffix: ''
            },
            {
                suffix: 'percentageSymbol',
                amount: data?.entity?.defaultPercentage
                    ? data?.entity?.defaultPercentage
                    : investmentFallbackStats.defaultPercentage,
                title: 'Default Percentage',
                color: theme?.colors?.primary?.[30],
                decimalPlaces: 2,
                countSuffix: ''
            },
        ];
    }

    return [];
};

export const getSignUpStats = (data) => {
    if (data) {
        return [
            {
                id: 'totalInvestment',
                suffix: 'rupeeSymbol',
                amount: data?.entity?.investedAmount
                    ? data?.entity?.investedAmount
                    : investmentFallbackStats.investedAmount,
                tagline: 'cr.',
                title: 'Total Investment',
                color: theme?.colors?.primary?.[500],
                category: '',
                duration: 1.3,
                countSuffix: '+',
            },
            {
                id: 'avgReturns',
                suffix: 'percentageSymbol',
                amount: data?.entity?.averageReturns
                    ? data?.entity?.averageReturns
                    : investmentFallbackStats.averageReturns,
                tagline: '',
                title: 'Avg Returns Earned',
                color: theme?.colors?.primary?.[30],
                category: 'Per Annum',
                categoryShortName: 'p.a',
                decimalPlaces: 1,
                duration: 0.5,
                delay: 1.5,
                countSuffix: '',
            },
            {
                id: 'registeredUsers',
                amount: investmentFallbackStats.registeredUsers,
                tagline: 'lakh',
                title: 'Registered Users',
                color: theme?.colors?.primary?.[500],
                category: '',
                duration: 1.3,
                countSuffix: '+',
            },
        ];
    }

    return [];
};

export const displayCohort = (preferences) => {
    preferences.sort();
    let displayName = '';
    preferences.map((preference) => {
        switch (preference) {
            case 'AllInvestor':
                displayName = 'All Investors';
                break;
            case 'IFAOnboardedInvestor':
                if (displayName.length === 0) displayName = 'IFA';
                else displayName += ' + IFA';
                break;
            case 'OnlyForFirstTimeInvestor':
                if (displayName.length === 0) displayName += 'FTI';
                else displayName += ' + FTI';
                break;
            case 'ZeroAUMInvestor':
                if (displayName.length === 0) displayName += 'Zero AUM';
                else displayName += ' + Zero AUM';
                break;
            case 'D2CInvestor':
                if (displayName.length === 0) displayName += 'D2C';
                else displayName += ' + D2C';
                break;
            default:
                break;
        }
    });
    return displayName;
};

export const checkIfInvestmentExpired = async (paymentId) => {
    const paymentResponse = await getPaymentById(paymentId);
    return paymentResponse?.entity?.status === 'Timeout';
};

export const reloadAuthenticatedTabs = () => {
    const channel = new BroadcastChannel('logout-event');
    channel.postMessage('logout');
    channel.close();
};

export const listenBroadCastMessage = (message, type = 'reload', router) => {
    if (message === 'logout') {
        removeAuth();
        switch (type) {
            case 'reload':
                router.reload();
                break;
            case 'logout':
                router.push('/logout');
                break;
            case 'logout-admin':
                router.push('/adminLogin');
                break;
            default:
                router.reload();
        }
    }
};

export const deductMinutes = (timestamp = new Date(), min = 0) => {
    const SECONDS = 60;
    const MILLISECONDS = 1000;
    const timestampAfterDeduction =
        new Date(timestamp) - min * SECONDS * MILLISECONDS;
    return new Date(timestampAfterDeduction).toISOString();
};

export const saveItemToLocalStorage = (key, details) => {
    return localStorage.setItem(key, JSON.stringify(details));
};

export const getItemFromLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const removeItemFromLocalStorage = (key) => {
    if (localStorage.getItem(key)) localStorage.removeItem(key);
};

export const revalidateInvestmentStats = (currentStats) => {
    let details = getItemFromLocalStorage('investmentStats');
    if (details) {
        const result = [
            'averageReturns',
            'defaultPercentage',
            'investedAmount',
            'repaidAmount',
        ].reduce((acc, val) => {
            if (details?.['entity']?.[val] < currentStats?.['entity']?.[val]) {
                acc = true;
            }
            return acc;
        }, false);

        if (result) {
            const finalObj = {
                ...currentStats,
                expiry: Moment().add(12, 'hours'),
            };
            saveItemToLocalStorage('investmentStats', finalObj);
            return currentStats;
        } else {
            const expiry = Moment(details.expiry);
            if (expiry.isAfter(Moment())) {
                return details;
            } else {
                const finalObj = {
                    ...details,
                    expiry: Moment().add(12, 'hours'),
                };
                saveItemToLocalStorage('investmentStats', finalObj);
                return details;
            }
        }
    } else {
        const finalObj = {
            ...currentStats,
            expiry: Moment().add(12, 'hours'),
        };
        saveItemToLocalStorage('investmentStats', finalObj);
        return currentStats;
    }
};

/*
 * Replace words in a string
 * originalString
 * replacementPairs: [['font-bold', 'font-sembold'], ....]
 */
export const multiReplaceInString = (originalString, replacementPairs) => {
    replacementPairs.forEach(([replacingWord, replacedWord]) => {
        const regex = new RegExp(replacingWord, 'g');
        originalString = originalString?.replace(regex, replacedWord);
    });

    return originalString;
}

export const setLocalStorageItemWithExpiration = (key, valueName, value, expirationInHours = 1) => {
    const currentTime = new Date().getTime();
    const expirationTime = currentTime + expirationInHours * 60 * 60 * 1000;
    const item = {
        [valueName]: value,
        expiryAt: expirationTime
    };
    saveItemToLocalStorage(key, item);
}

export const isTimeExpired = (item) => {
    if (!item?.expiryAt) {
        return true
    }
    const currentTime = new Date().getTime();
    return currentTime > item.expiryAt;
}

export const triggerCustomEvent = (eventName, values) => {
    if (window?.dataLayer)
        window.dataLayer.push({ 'event': eventName, ...values });
}

export const numberOfDaysBetweenTwodates = (
    firstDate,
    secondDate
) => {
    const date1 = Moment(firstDate).utc().utcOffset('+05:30').startOf('day');
    const date2 = Moment(secondDate).utc().utcOffset('+05:30').startOf('day');
    const daysDiff = date1.diff(date2, 'days');
    return daysDiff;
};

const revalidateFestiveSeasonConfig = (storageKey) => {
    const details = getItemFromLocalStorage(storageKey);
    if (details) {
        const expiry = Moment(details.expiry);
        if (expiry.isAfter(Moment())) {
            return true;
        } else {
            removeItemFromLocalStorage(storageKey);
            return false;
        }
    } else {
        return false;
    }
}

export const getFestivalConfiguration = async () => {
    if (revalidateFestiveSeasonConfig('festiveSeason')) {
        const festiveSeasonsData = getItemFromLocalStorage('festiveSeason');
        return festiveSeasonsData;
    }

    const response = await getFestiveSeasonConfiguration();

    if (isValidResponseEntity(response)) {
        let festiveSeasonsData = {
            ...response.entity,
            expiry: Moment().add(6, 'hours'),
        };
        saveItemToLocalStorage('festiveSeason', festiveSeasonsData);
        return response.entity;
    }

    return null;
};

export const getFestivalSignupConfiguration = async () => {
    if (revalidateFestiveSeasonConfig('festiveSeasonSignup')) {
        const festiveSeasonsData = getItemFromLocalStorage('festiveSeasonSignup');
        return festiveSeasonsData;
    }

    const response = await getOpenConfigurationByEntityType("FestiveSeasonOfProsperitySignup");

    if (isValidResponseEntity(response)) {
        let festiveSeasonsData = {
            ...response.entity,
            expiry: Moment().add(6, 'hours'),
        };
        saveItemToLocalStorage('festiveSeasonSignup', festiveSeasonsData);
        return response.entity;
    }

    return null;
};

export const hideFreshchat = () => {
    // Delay the setup to ensure Freshchat is fully loaded
    const delaySetup = () => {
        if (window.fcWidget) {
            // Freshchat is already loaded, set up event listener
            window.fcWidget.hide();
        } else {
            // Freshchat is not loaded, retry in a short time
            setTimeout(delaySetup, 50);
        }
    };
    if (process.env.NEXT_PUBLIC_FRESHCHAT_TOKEN !== 'FC-dummy') delaySetup();
};

export const showFreshchat = () => {
    if (
        window.fcWidget &&
        process.env.NEXT_PUBLIC_FRESHCHAT_TOKEN !== 'FC-dummy'
    ) {
        window.fcWidget.show();
    }
};

export const getKycNudgeDetails = async (userId) => {
    const details = getItemFromLocalStorage('kycNudgeDetails');
    if (details) return details;
    const response = await getKycNudgeDetailsByUserId(userId);
    saveItemToLocalStorage('kycNudgeDetails', response);
    return response;
};
export const getInvestedAmount = (
    isNCD,
    initialFaceValue,
    totalPurchasedUnits,
    totalInvestedAmount,
    totalPromisedUnits
) => {
    if (isNCD) { // if opportunity type is ncd type then returning purchased units * initialFaceValue
        return initialFaceValue * (totalPurchasedUnits + totalPromisedUnits);
    }

    return getEitherOrDefaultValueOf(totalInvestedAmount);
};

export const countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
};

export const brandNameMapping = {
    [brandNames.ALT_SMART]: {
        bgColor: 'bg-altSmart-bg-50',
        borderColor: 'border-altSmart-100',
        shadow: 'shadow-altSmart-glow',
    },
    [brandNames.ALT_ARMOUR]: {
        bgColor: 'bg-altArmour-bg-50',
        borderColor: 'border-altArmour-100',
        shadow: 'shadow-altArmour-glow',
    },
    [brandNames.ALT_BLU]: {
        bgColor: 'bg-altBlu-bg-50',
        borderColor: 'border-altBlu-100',
        shadow: 'shadow-altBlu-glow',
    },
    [brandNames.ALT_PACK]: {
        bgColor: 'bg-altPack-bg-50',
        borderColor: 'border-altPack-100',
        shadow: 'shadow-altPack-glow',
    },
    [brandNames.ALT_WINGS]: {
        bgColor: 'bg-altWings-bg-50',
        borderColor: 'border-altWings-100',
        shadow: 'shadow-altWings-glow',
    },
};

export const opportunityTypeMapping = {
    [OpportunityTypes.INVOICE_DISCOUNTING]: {
        bgColor: 'bg-altWings-bg-50',
        borderColor: 'border-altWings-100',
        shadow: 'shadow-altWings-glow',
    },
    [OpportunityTypes.ASSET_BACKED_LEASING]: {
        bgColor: 'bg-steel-blue-bg-50',
        borderColor: 'border-steelBlue-100',
        shadow: 'shadow-abl-glow',
    },
    [OpportunityTypes.CORPORATE_DEBT]: {
        bgColor: 'bg-pink-bg-50',
        borderColor: 'border-pink-70',
        shadow: 'shadow-cd-glow',
    },
    [OpportunityTypes.VENTURE_DEBT]: {
        bgColor: 'bg-sky-blue-bg-50',
        borderColor: 'border-skyBlue-100',

        shadow: 'shadow-vd-glow',
    },
    [OpportunityTypes.TREASURY_BILL]: {
        bgColor: 'bg-skin-bg-50',
        borderColor: 'border-skin-100',
        shadow: 'shadow-tb-glow',
    },
};

export const getBrandBg = (brandName, type) => {
    const defaultValues = {
        bgColor: 'bg-pink-bg-50',
        borderColor: 'border-pink-70',
        shadow: 'shadow-cd-glow',
    };
    if (brandName) {
        return brandNameMapping[brandName] || defaultValues;
    } else {
        return opportunityTypeMapping[type] || defaultValues;
    }
};

export const appendCountryCodeAndMaskMobile = (mobile, countryCode) => {
    const nbsp = String.fromCharCode(160); // Non-breaking space
    return `+${countryCode}${nbsp}${'*'.repeat(
        Math.max(
            0,
            mobile?.length -
            countryCode?.length -
            3
        )
    )}${mobile.substr(-3)}`;
};

export const appendCountryCodeAndFormatMobile = (mobile, countryCode) =>
    `+${countryCode} ${mobile.substr(countryCode.length)}`;

export const maskEmail = (email) => {
    if (!email) return '';

    const [localPart, domain] = email.split('@');

    let maskedLocalPart;

    // If local part has only one or two characters, mask all
    if (localPart.length < 3) {
        maskedLocalPart = '*'.repeat(localPart.length);
    }
    // If local part has only three characters, mask all except first char
    else if (localPart.length === 3) {
        maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart.length - 1)}`;
    } else {
        // Masking the local part, leaving the first and last character visible
        maskedLocalPart = `${localPart[0]}${'*'.repeat(localPart.length - 2)}${localPart[localPart.length - 1]}`;
    }
    // Reconstruct the email with the masked local part and the domain
    return `${maskedLocalPart}@${domain}`;
}

export const isOpportunityFullySubscribed = ({
    investedAmount,
    opportunity,
    progress
}) => {
    const { type, initialFaceValue, dealSize } = opportunity;
    const totalPromisedAmount = isUnitsBased(type) ? (progress?.totalPromisedUnits || 0) * initialFaceValue : progress?.totalPromisedAmount || 0;

    const subscribedAmount = investedAmount - totalPromisedAmount;

    // TODO Does State Completed Exists?? Please check and refactor.
    return (subscribedAmount >= dealSize) || opportunity?.state === 'Completed' || (opportunityClosedStates.includes(opportunity?.state));
}

export const getBlogMetaKeywords = (title, categories) => {
    // Common words to be removed
    const commonWords = new Set([
        'of', 'or', 'in', 'on', 'is', 'you', 'and', 'the', 'a', 'an', 'to', 'for', 'with',
        'how', 'why', 'what', 'when', 'where', 'which', 'who', 'whom', 'this', 'that', 'these', 'those', ',', ':', '-', ',', '?', '!', '/', '.'
    ]);
    // Function to split the title into words and filter out common words
    const processTitle = (title) => {
        return title?.toLowerCase()?.split(/\s+/)?.filter((word) => !commonWords.has(word)) || []; // Remove common words
    };
    // Process the title
    const titleKeywords = processTitle(title);
    // Process the categories by splitting and trimming each category
    const categoryKeywords = categories ? categories.split(',').map((category) => category?.trim()?.toLowerCase()) : [];
    // Combine title, title keywords and category keywords and remove duplicates
    const keywords = [...new Set([title?.toLowerCase(), ...titleKeywords, ...categoryKeywords])];

    return keywords.join(', ');
};

export const triggerMenuGaEvents = (name) => {
    switch (name.toLocaleLowerCase()) {
        case 'webinars':
            triggerCustomEvent(gaEventNameEnum.WEBINAR_LINK_CLICKED);
            break;
        default:
            break;
    }
}

export const getCurrentPageRecords = (page, records, limit) => {
    const lowerLimit = (page - 1) * limit;
    const upperLimit = page * limit;
    let currentWebinars = records?.slice(lowerLimit, upperLimit);

    return currentWebinars;
}

export const shuffleArray = (array) =>
    array.forEach((_, index) => {
        const randomIndex = Math.floor(Math.random() * (index + 1));
        [array[index], array[randomIndex]] = [array[randomIndex], array[index]];
    });


export const formatRealisedIRR = (irr) => {
    if (irr === null || irr === undefined) {
        return '-';
    }
    if (irr === 0) {
        return '0%';
    }

    return `${removeTrailingZeros(irr)}%`;
};

export const isTimestampExpired = (savedData, threshold = 3, unit = 'hours') => {
    if (!savedData?.timestamp) return true;

    const savedTime = Moment(savedData.timestamp);
    const now = Moment();
    const diff = now.diff(savedTime, unit);

    return diff > threshold;
};

export const getKycredirectionText = () => {
    const auth = getAuth();
    if(auth?.accountType === 'individual') {
        return 'Complete Kyc';
    } else {
        return 'Start Kyc';
    };
};

export const handleKycRedirection = (router, query = {}) => {
    const auth = getAuth();
    if(auth?.accountType === 'individual') {
        router.push({
            pathname: '/kyc',
            query: { ...query },
        });
    } else {
        router.push({
            pathname: '/start-kyc',
            query: { ...query },
        });
    };
};

export const statusToVariant = {
    'Fully Repaid': 'fullyRepaid',
    Delayed: 'delayed',
    'On Time': 'onTime',
    'In Process': 'inProcess',
};

export const toastMessage = (message, error, setError, setMessage) => {
    toast.info(
        <p className="font-paragraph text-primary-700 text-sm font-medium">
            {message || error}
        </p>,
        {
            position: 'top-center',
            progress: null,
            style: {
                '--toastify-color-progress-info':
                    '#26247B', // Replace with your desired progress bar color
                '--toastify-icon-color-info': '#26247B',
            },
            autoClose: 5000,
        }
    );
    setError(null);
    setMessage(null);
};

export const getCapitalizedString = (text) => {
    if( typeof text === 'string') 
        return text.toLowerCase().replace(/\b\w/g, val => val.toUpperCase());
    return text?.toString() || null;
};

export const getUpiLimit= (paymentGateways) =>{
    let upiLimit = '';
    if (paymentGateways?.length === 1) {
        switch (paymentGateways[0]?.name) {
            case paymentGatewayEnum.PAYU:
                upiLimit = '2 Lacs';
                break;
            default:
                upiLimit = '1 Lac';
        }
    }
    else{
        upiLimit = '2 Lacs'
    }
    return upiLimit;
}

export const isAdminOnlyDeal = (opportunityAccessCode) => opportunityAccessCode === process.env.NEXT_PUBLIC_ADMIN_ACCESS_CODE;
